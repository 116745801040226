export const PERMISSIONS = {
  VIEW_USER: 'view_user',
  VIEW_USER_LIST: 'view_user_list',
  INVITE_USER: 'invite_user',
  EDIT_USER: 'edit_user',
  DELETE_USER: 'delete_user',
  VIEW_ROLE: 'view_role',
  VIEW_ROLE_LIST: 'view_role_list',
  CREATE_ROLE: 'create_role',
  EDIT_ROLE: 'edit_role',
  DELETE_ROLE: 'delete_role',
  VIEW_LOG_LIST: 'view_log_list',
  VIEW_TRANSLATION_LIST: 'view_translations_list',
  VIEW_FINANCIALS_LIST: 'view_financials_list',
  VIEW_DATA_LIST_LIST: 'view_data_list_list',

  // Templates
  VIEW_TEMPLATES_LIST: 'view_templates_list',

  //
  EDIT_BROKER: 'edit_broker',

  // Permissions data
  VIEW_PERMISSION_LIST: 'view_permission_list',
  CREATE_PERMISSION: 'create_permission',
  EDIT_PERMISSION: 'edit_permission',
  DELETE_PERMISSION: 'delete_permission'
};

export const FULL_PERMISSIONS = Object.values(PERMISSIONS);
