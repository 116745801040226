import { PERMISSIONS } from 'constants/security';

const list = [
  PERMISSIONS.INVITE_USER,
  PERMISSIONS.DELETE_USER,
  PERMISSIONS.VIEW_USER,
  PERMISSIONS.EDIT_USER,
  PERMISSIONS.VIEW_USER_LIST,
  PERMISSIONS.CREATE_ROLE,
  PERMISSIONS.DELETE_ROLE,
  PERMISSIONS.VIEW_ROLE,
  PERMISSIONS.EDIT_ROLE,
  PERMISSIONS.VIEW_ROLE_LIST,
  PERMISSIONS.VIEW_LOG_LIST
];

const permissions = {
  name(i) {
    return list[i % list.length];
  },
  description(i) {
    return `This is role ${i}`;
  },
  applicationId() {
    return 0;
  }
};

export default permissions;
