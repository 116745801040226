import React from 'react';

import { ReactComponent as StackIcon } from 'icons/stack.svg';
import { ReactComponent as DashboardIcon } from 'icons/dashboard.svg';
import { ReactComponent as WorldIcon } from 'icons/world.svg';
import { ReactComponent as DocsIcon } from 'icons/docs.svg';
import { ReactComponent as FinancialIcon } from 'icons/financial.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';

import i18n from 'utils/translations';

import { hasPermission } from 'utils/security';

import { PERMISSIONS } from 'constants/security';

const redirect = url => {
  window.location.href = url;
};

export const languages = [{
  label: 'EN',
  switch: () => i18n.changeLanguage('EN')
}, {
  label: 'RO',
  switch: () => i18n.changeLanguage('RO')
}];

export const getApps = user => (
  [{
    name: 'Logs',
    icon: <StackIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_LOG_LIST]),
    onClick: () => redirect(process.env.REACT_APP_ANALYTICS)
  }, {
    name: 'Dashboard',
    icon: <DashboardIcon className="w-5 h-5" />,
    hasPermission: true,
    onClick: () => redirect(process.env.REACT_APP_PTH)
  }, {
    name: 'Translations',
    icon: <WorldIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_TRANSLATION_LIST]),
    onClick: () => redirect(process.env.REACT_APP_TRANSLATIONS)
  }, {
    name: 'Data List',
    icon: <DocsIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_DATA_LIST_LIST]),
    onClick: () => redirect(process.env.REACT_APP_DATA_LIST)
  }, {
    name: 'Financial',
    icon: <FinancialIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_FINANCIALS_LIST]),
    onClick: () => redirect(`${process.env.REACT_APP_FINANCIAL}/discounts`)
  }, {
    name: 'Settings',
    icon: <SettingsIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_TEMPLATES_LIST]),
    onClick: () => redirect(`${process.env.REACT_APP_SETTINGS}/templates`)
  }]
);
