import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SideNav as SpacerSideNav } from 'spacer-ui-kit';

import { ReactComponent as UsersIcon } from 'icons/users-white.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings-white.svg';

const SideNav = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hiddenClass = 'hidden text-opacity-100 whitespace-nowrap overflow-hidden';
  const blockClass = 'block overflow-auto whitespace-normal text-opacity-100';
  const [visibleClass, setVisibleClass] = useState(blockClass);
  const [labelClass, setLabelClass] = useState(blockClass);
  const location = window.location.href.split('/')[3];
  const [selected, setSelected] = useState(location);

  const collapseSideNav = () => {
    setVisibleClass(visibleClass ? '' : blockClass);
    setLabelClass(labelClass === blockClass ? hiddenClass : blockClass);
  };

  const handleRouteClick = route => {
    history.push(`/${route}`);
    setSelected(route);
  };

  return (
    <SpacerSideNav onCollapse={() => collapseSideNav()}>
      <div
        onClick={() => handleRouteClick('users')}
        className={`${visibleClass} ${selected === 'users' ? 'bg-opacity-40' : 'bg-opacity-10'}
        bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
      >
        <div className="text-xl mr-2 p-2 overflow-hidden">
          <UsersIcon className="w-5 h-5" />
        </div>
        <div className={`${labelClass} animate-fade-in`}>{t('teams_side_button_users')}</div>
      </div>

      <div
        onClick={() => handleRouteClick('roles')}
        className={`${visibleClass} ${selected === 'roles' ? 'bg-opacity-40' : 'bg-opacity-10'}
        bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
      >
        <div className="text-xl mr-2 p-2 overflow-hidden">
          <SettingsIcon className="w-5 h-5" />
        </div>
        <div className={`${labelClass} animate-fade-in`}>{t('teams_side_button_roles')}</div>
      </div>
    </SpacerSideNav>
  );
};

export default SideNav;
