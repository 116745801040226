import { lazy } from 'react';

import { PERMISSIONS } from 'constants/security';

const Forbidden = lazy(() => import('pages/errors/forbidden'));
const Users = lazy(() => import('pages/users'));
const Roles = lazy(() => import('pages/roles/'));

export const routes = [{
  path: '/forbidden',
  exact: true,
  component: Forbidden,
  isPublic: true
}, {
  path: '/users',
  exact: true,
  component: Users,
  permissions: [PERMISSIONS.VIEW_USER_LIST]
}, {
  path: '/roles',
  exact: true,
  component: Roles,
  permissions: [PERMISSIONS.VIEW_ROLE_LIST]
}];
